import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { PrinciplesByEntity, SortDirection } from 'models';
import { useTranslation } from 'react-i18next';
import { formatAsPercent } from 'utils';

export const ChildResultDontKnow = ({
  principlesByEntity,
  saveToPngElementId,
}: {
  principlesByEntity: PrinciplesByEntity[];
  saveToPngElementId?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const formatter = (value: string | number | undefined) =>
    formatAsPercent(value, '-') as string;

  const { items, sorter } = useSortableData(principlesByEntity, {
    key: 'entityName',
    direction: SortDirection.Asc,
  });

  return (
    <TableResponsiveContainer>
      <Table id={saveToPngElementId}>
        <thead>
          <tr>
            <Th sortKey="entityName" sorter={sorter}>
              {t('CommonTerms.Entity')}
            </Th>

            <ThRight
              sortKey="structure"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.structure.percentageDontKnow,
                  b: b.structure.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Structure')}
            </ThRight>

            <ThRight
              sortKey="technology"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.technology.percentageDontKnow,
                  b: b.technology.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Technology')}
            </ThRight>

            <ThRight
              sortKey="culture"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.culture.percentageDontKnow,
                  b: b.culture.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Culture')}
            </ThRight>

            <ThRight
              sortKey="interaction"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.interaction.percentageDontKnow,
                  b: b.interaction.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Interaction')}
            </ThRight>

            <ThRight
              sortKey="motivation"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.motivation.percentageDontKnow,
                  b: b.motivation.percentageDontKnow,
                };
              }}
            >
              {t('CommonTerms.Motivation')}
            </ThRight>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => (
            <Tr key={index}>
              <Td>{item.entityName}</Td>
              <TdRight data-value={item.structure.percentageDontKnow}>
                {formatter(item.structure.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.technology.percentageDontKnow}>
                {formatter(item.technology.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.culture.percentageDontKnow}>
                {formatter(item.culture.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.interaction.percentageDontKnow}>
                {formatter(item.interaction.percentageDontKnow)}
              </TdRight>
              <TdRight data-value={item.motivation.percentageDontKnow}>
                {formatter(item.motivation.percentageDontKnow)}
              </TdRight>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableResponsiveContainer>
  );
};

ChildResultDontKnow.displayName = 'ChildResultDontKnow';
