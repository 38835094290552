import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { useGetSurveyStructureQuery } from '@dimatech/features-survey/lib/api/survey/surveyApi';
import { useGetPreviousEntityId } from '@dimatech/features-survey/lib/hooks/useGetPreviousEntityId';
import { useGetSurveyInstances } from '@dimatech/features-survey/lib/hooks/useGetSurveyInstances';
import { AlertInfo, AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  selectFilterAllCustomers,
  selectFilterByEntity,
} from 'api/dikiosSlice';
import {
  useGetOverviewByOptionQuery,
  useGetOverviewQuery,
} from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { config } from 'config';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EntityType, FilterableEntityType } from 'models';
import { useContext, useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AverageByOrganisationAndQuestion } from './components/AverageByOrganisationAndQuestion';
import { OurResultByCategory } from './components/OurResultByCategory';

export const QuestionAnalyze = (): JSX.Element | null => {
  const { t } = useTranslation();
  const filterByEntity = useAppSelector(selectFilterByEntity);
  const filterAllCustomers = useAppSelector(selectFilterAllCustomers);
  const { accessToken } = useContext(AuthenticationContext);
  const customerId = accessToken.customerId;

  const isCarelessRespondentEnabledFlagOn =
    useFlags()[flags.permanent.app.dikios.isCarelessRespondentEnabled];

  const averageByOrganisationAndQuestionId = useId();

  const { previousEntityId } = useGetPreviousEntityId({
    instanceId: filterByEntity.instance?.id,
    previousInstanceId: filterByEntity.previousInstance?.id,
    entityId: filterByEntity.entityId,
    customerId: customerId as string,
  });

  let entityType = accessToken.user?.currentCustomer
    .customerType as FilterableEntityType;

  if (
    !(
      [
        EntityType.Municipality,
        EntityType.Government,
        EntityType.Region,
      ] as FilterableEntityType[]
    ).includes(entityType)
  ) {
    entityType = EntityType.Other;
  }

  const { instances, isSuccess: isSuccessInstances } =
    useGetSurveyInstances(true);

  const {
    data: overview,
    isFetching: isFetchingOverview,
    isLoading: isLoadingOverview,
  } = useGetOverviewQuery(
    filterByEntity.instance && customerId && accessToken.user?.id
      ? {
          filter: filterByEntity,
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: overviewPreviousPeriod,
    isFetching: isFetchingOverviewPreviousPeriod,
    isLoading: isLoadingOverviewPreviousPeriod,
  } = useGetOverviewQuery(
    filterByEntity.previousInstance &&
      filterByEntity.entityId &&
      customerId &&
      previousEntityId &&
      accessToken.user?.id
      ? {
          filter: {
            ...filterByEntity,
            instance: filterByEntity.previousInstance,
            entityId: previousEntityId,
          },
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: principlesByOption,
    isFetching: isFetchingByOption,
    isLoading: isLoadingByOption,
  } = useGetOverviewByOptionQuery(
    filterByEntity.instance && customerId && accessToken.user?.id
      ? {
          filter: filterByEntity,
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: overviewNationally,
    isFetching: isFetchingOverviewNationally,
    isLoading: isLoadingOverviewNationally,
  } = useGetOverviewQuery(
    filterAllCustomers.instance && customerId && accessToken.user?.id
      ? {
          filter: {
            ...filterAllCustomers,
            entityType: accessToken.isInternational ? undefined : entityType,
          },
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: questions,
    isFetching: isFetchingQuestions,
    isLoading: isLoadingQuestions,
  } = useGetSurveyStructureQuery(
    customerId
      ? {
          surveyId: config.survey?.id as string,
          _customerId: customerId,
        }
      : skipToken
  );

  const isLoading =
    isLoadingOverview ||
    isLoadingOverviewNationally ||
    isLoadingByOption ||
    isLoadingQuestions ||
    isFetchingOverviewPreviousPeriod ||
    isLoadingOverviewPreviousPeriod;

  return (
    <>
      <ViewHeader>
        <Heading1>{t('QuestionAnalyze.Title')}</Heading1>
        <SelectExtendedFilters
          isCarelessFilterShown={isCarelessRespondentEnabledFlagOn}
          isRespondentFilterShown={false}
        />
      </ViewHeader>

      {isLoading ? (
        <LoaderFill />
      ) : (
        <>
          {overview && overview.principles.length === 0 && (
            <Card width="100%">
              <AlertInfo>{t('QuestionAnalyze.NoData')}</AlertInfo>
            </Card>
          )}

          {(!instances || instances.length === 0) && isSuccessInstances && (
            <Card width="100%">
              <AlertWarning>{t('QuestionAnalyze.NoInstances')}</AlertWarning>
            </Card>
          )}

          {questions &&
            principlesByOption &&
            principlesByOption.length > 0 &&
            instances &&
            instances.length > 0 && (
              <Card
                title={t('QuestionAnalyze.OurResultByQuestionAndOption.Title')}
                helpText={
                  <Trans
                    i18nKey={
                      'QuestionAnalyze.OurResultByQuestionAndOption.Help'
                    }
                  />
                }
                width="100%"
                canBeSavedAsPng={true}
              >
                <CardBody isLoading={isFetchingByOption}>
                  <OurResultByCategory
                    principlesByOption={principlesByOption}
                    questions={questions}
                  />
                </CardBody>
              </Card>
            )}

          {questions &&
            overview &&
            overview.principles.length > 0 &&
            instances &&
            instances.length > 0 && (
              <Card
                title={t(
                  'QuestionAnalyze.AverageByOrganisationAndQuestion.Title'
                )}
                width="100%"
                canBeSavedAsPng={true}
                canBeSavedAsCsv={true}
                saveToPngElementId={averageByOrganisationAndQuestionId}
              >
                <CardBody
                  isLoading={
                    isFetchingOverview ||
                    isFetchingOverviewNationally ||
                    isFetchingQuestions
                  }
                >
                  <AverageByOrganisationAndQuestion
                    principles={overview.principles}
                    principlesPreviousPeriod={
                      overviewPreviousPeriod?.principles
                    }
                    principlesNationally={overviewNationally?.principles}
                    questions={questions}
                    saveToPngElementId={averageByOrganisationAndQuestionId}
                  />
                </CardBody>
              </Card>
            )}
        </>
      )}
    </>
  );
};

QuestionAnalyze.displayName = 'QuestionAnalyze';
