import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { selectFilterByEntity } from 'api/dikiosSlice';
import { config } from 'config';
import { useAppSelector } from 'hooks';
import { Principle, Question, SortDirection } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsPercent, getSubCategoryDefinitions } from 'utils';

export const AverageByOrganisationAndQuestion = ({
  principles,
  principlesPreviousPeriod,
  principlesNationally,
  questions,
  saveToPngElementId,
}: {
  principles: Principle[];
  principlesPreviousPeriod?: Principle[];
  principlesNationally?: Principle[];
  questions: Question[];
  saveToPngElementId?: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const filterByEntity = useAppSelector(selectFilterByEntity);

  const subCategoryDefinitions = getSubCategoryDefinitions();

  const mapped = principles.map((principle) => {
    const nationally = principlesNationally?.find(
      (national) => principle.principleId === national.principleId
    );

    const question = questions.find(
      (question) => question.id.toString() === principle.principleId
    );

    const previousPeriod = principlesPreviousPeriod?.find(
      (previous) => principle.principleId === previous.principleId
    );

    return {
      ...principle,
      name: t(
        `Survey.${config.survey?.id}.Question.${principle.principleId}.Principle`
      ),
      previousPeriodPercent: previousPeriod?.percentage,
      nationallyPercent: nationally?.percentage,
      nationallyPercentDontKnow: nationally?.percentageDontKnow,
      sortOrder: question?.sortOrder ?? 0,
      subCategoryKey: subCategoryDefinitions.find((category) =>
        category.principles.includes(parseInt(principle.principleId))
      )?.key,
    };
  });

  const { items, sorter } = useSortableData(mapped, {
    key: 'sortOrder',
    direction: SortDirection.Asc,
  });

  return (
    <TableResponsiveContainer>
      <Table id={saveToPngElementId}>
        <thead>
          <tr>
            <Th sortKey="name" sorter={sorter}>
              {t('QuestionAnalyze.AverageByOrganisationAndQuestion.Principle')}
            </Th>
            <Th sortKey="subCategoryKey" sorter={sorter}>
              {t('QuestionAnalyze.AverageByOrganisationAndQuestion.Category')}
            </Th>
            <ThCentered
              style={{ width: 150 }}
              sortKey="percentage"
              sorter={sorter}
            >
              {t('QuestionAnalyze.AverageByOrganisationAndQuestion.Percent')}
            </ThCentered>

            {principlesPreviousPeriod &&
              filterByEntity.previousInstance &&
              filterByEntity.entityId && (
                <ThCentered
                  style={{ width: 150 }}
                  sortKey="previousPeriodPercent"
                  sorter={sorter}
                >
                  {t(
                    'QuestionAnalyze.AverageByOrganisationAndQuestion.PercentPreviousPeriod',
                    { period: filterByEntity.previousInstance.name }
                  )}
                </ThCentered>
              )}

            <ThRight
              style={{ width: 150 }}
              sortKey="percentageDontKnow"
              sorter={sorter}
            >
              {t(
                'QuestionAnalyze.AverageByOrganisationAndQuestion.PercentDontKnow'
              )}
            </ThRight>

            <ThRight
              style={{ width: 150 }}
              sortKey="nationallyPercent"
              sorter={sorter}
            >
              {t(
                `QuestionAnalyze.AverageByOrganisationAndQuestion.${
                  accessToken.isInternational
                    ? 'SwedishPercent'
                    : 'NationallyPercent'
                }`
              )}
            </ThRight>
            <ThRight
              style={{ width: 150 }}
              sortKey="nationallyPercentDontKnow"
              sorter={sorter}
            >
              {t(
                `QuestionAnalyze.AverageByOrganisationAndQuestion.${
                  accessToken.isInternational
                    ? 'SwedishPercentDontKnow'
                    : 'NationallyPercentDontKnow'
                }`
              )}
            </ThRight>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => {
            return (
              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td data-value={item.subCategoryKey}>
                  {t(`CommonTerms.${item.subCategoryKey}`)}
                </Td>
                <TdCentered data-value={(item.percentage ?? 0) * 100}>
                  <Progress
                    formatter={formatAsPercent}
                    value={(item.percentage ?? 0) * 100}
                    singleValue={true}
                  />
                </TdCentered>

                {principlesPreviousPeriod &&
                  filterByEntity.previousInstance &&
                  filterByEntity.entityId && (
                    <TdCentered
                      data-value={(item.previousPeriodPercent ?? 0) * 100}
                    >
                      <Progress
                        formatter={formatAsPercent}
                        value={(item.previousPeriodPercent ?? 0) * 100}
                        singleValue={true}
                      />
                    </TdCentered>
                  )}

                <TdRight data-value={item.percentageDontKnow * 100}>
                  {formatAsPercent(item.percentageDontKnow * 100)}
                </TdRight>
                <TdRight data-value={(item.nationallyPercent ?? 0) * 100}>
                  {formatAsPercent((item.nationallyPercent ?? 0) * 100)}
                </TdRight>
                <TdRight
                  data-value={(item.nationallyPercentDontKnow ?? 0) * 100}
                >
                  {formatAsPercent((item.nationallyPercentDontKnow ?? 0) * 100)}
                </TdRight>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </TableResponsiveContainer>
  );
};

AverageByOrganisationAndQuestion.displayName =
  'AverageByOrganisationAndQuestion';
