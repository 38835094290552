/* 
We allow this here since this is the entry point of 
getting all settings from the .env files
*/
/* eslint-disable no-process-env */
import {
  config as sharedConfig,
  Config as SharedConfig,
} from '@dimatech/shared/lib/config';
import { StatementCategory, SubCategoryDefinition } from 'models';

// NOTE: Add settings here that are unique for this app, shared settings should be added to shared config

export const config: Config = {
  ...sharedConfig,
  authentication: {
    ...sharedConfig.authentication,
    // Do not allow users with only CommonUser role
    allowCommonUserInPrivateRoutes: false,
  },
  dikios: {
    principalOptions: [11, 12, 13, 14, 15, 16, 17],
    categories: {
      structure: {
        key: StatementCategory.Structure,
        id: 1,
        principles: [2, 3, 4, 5],
      },
      technology: {
        key: StatementCategory.Technology,
        id: 2,
        principles: [6, 7, 8, 9],
      },
      culture: {
        key: StatementCategory.Culture,
        id: 3,
        principles: [10, 11, 12, 13],
      },
      interaction: {
        key: StatementCategory.Interaction,
        id: 4,
        principles: [14, 15, 16],
      },
      motivation: {
        key: StatementCategory.Motivation,
        id: 5,
        principles: [17, 18, 19],
      },
    },
    occupationQuestionId: 20,
  },
};

type Config = SharedConfig & {
  dikios: {
    principalOptions: number[];
    categories: {
      structure: SubCategoryDefinition;
      technology: SubCategoryDefinition;
      culture: SubCategoryDefinition;
      interaction: SubCategoryDefinition;
      motivation: SubCategoryDefinition;
    };
    occupationQuestionId: number;
  };
};
