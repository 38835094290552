import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SurveyFilter, SurveyInstance } from 'models';
import { RootState } from 'store';

type State = {
  filter: SurveyFilter;
};

const initialState: State = {
  filter: {
    entityId: '',
    includePoos: false,
    includeOnlyInvolvedRespondents: true,
    carelessFilter: false,
  },
};

export const { reducer: dikiosReducer, actions: dikiosActions } = createSlice({
  name: 'dikios',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<SurveyFilter>) => {
      state.filter = action.payload;
    },
    setFilterEntityId: (state, action: PayloadAction<string>) => {
      state.filter.entityId = action.payload;
    },
    setFilterInstance: (
      state,
      action: PayloadAction<{
        instance: SurveyInstance;
        previousInstance?: SurveyInstance;
      }>
    ) => {
      state.filter.instance = action.payload.instance;
      state.filter.previousInstance = action.payload.previousInstance;
    },
    setFilterOccupation: (state, action: PayloadAction<number | undefined>) => {
      state.filter.occupation = action.payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
    resetEntityFilter: (state) => {
      state.filter = {
        ...state.filter,
        entityId: '',
      };
    },
  },
  extraReducers: (build) => {
    build.addCase(appActions.resetApplication, () => initialState);
  },
});

// Use this filter only when setting filter values
export const selectFilter = (state: RootState): SurveyFilter =>
  state.dikios.filter;

// Use this filter when filtering on entity (parent) only
export const selectFilterByEntity = (state: RootState): SurveyFilter =>
  ({
    ...state.dikios.filter,
    entityType: undefined,
    allCustomers: false,
  } as SurveyFilter);

// Use this filter when filtering by customer only
export const selectFilterByCustomer = (state: RootState): SurveyFilter =>
  ({
    ...state.dikios.filter,
    entityId: '',
    allCustomers: undefined,
  } as SurveyFilter);

// Use this filter when filtering on all customers by entity type
export const selectFilterByEntityType = (state: RootState): SurveyFilter =>
  ({
    ...state.dikios.filter,
    entityId: '',
    allCustomers: true,
  } as SurveyFilter);

// Use this filter when filtering on all customers regardless of entity type
export const selectFilterAllCustomers = (state: RootState): SurveyFilter =>
  ({
    ...state.dikios.filter,
    entityId: '',
    entityType: undefined,
    allCustomers: true,
  } as SurveyFilter);
