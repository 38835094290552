import { AdministrateRespondents as AdministrateRespondentsFeature } from '@dimatech/features-survey/lib/features/administrate-respondents';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { CacheTags, dikiosApi } from 'api/dikiosApi';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateRespondents = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isAllowEntitySelectionForAnonymousUsersEnabledFlagOn =
    useFlags()[
      flags.permanent.app.dikios.isAllowEntitySelectionForAnonymousUsersEnabled
    ];

  const handleRespondentMovedOrDeleted = () => {
    dispatch(
      dikiosApi.util.invalidateTags([
        CacheTags.Overview,
        CacheTags.Careless,
        CacheTags.Progress,
        CacheTags.ProgressByEntity,
        CacheTags.OverviewByOption,
        CacheTags.OverviewByCustomer,
        CacheTags.OverviewByEntity,
      ])
    );
  };

  return (
    <View title={t('Administrate.Respondent.Title')}>
      <AdministrateRespondentsFeature
        enableAllowEntitySelectionForAnonymousUsers={
          isAllowEntitySelectionForAnonymousUsersEnabledFlagOn
        }
        handleRespondentMovedOrDeleted={handleRespondentMovedOrDeleted}
      />
    </View>
  );
};

AdministrateRespondents.displayName = 'AdministrateRespondents';
