import { Breakpoints } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import { PrincipleOptionByPrinciple, Question } from 'models';
import { useTranslation } from 'react-i18next';
import {
  BsDiagram3,
  BsGear,
  BsGlobe2,
  BsLightbulb,
  BsPeople,
} from 'react-icons/bs';
import styled from 'styled-components';
import { OurResultByQuestionAndOption } from './OurResultByQuestionAndOption';

export const OurResultByCategory = ({
  principlesByOption,
  questions,
}: {
  principlesByOption: PrincipleOptionByPrinciple[];
  questions: Question[];
}): JSX.Element => {
  const { t } = useTranslation();

  const structure = principlesByOption.filter((item) =>
    config.dikios.categories.structure.principles.includes(
      parseInt(item.principleId)
    )
  );
  const technology = principlesByOption.filter((item) =>
    config.dikios.categories.technology.principles.includes(
      parseInt(item.principleId)
    )
  );
  const culture = principlesByOption.filter((item) =>
    config.dikios.categories.culture.principles.includes(
      parseInt(item.principleId)
    )
  );
  const interaction = principlesByOption.filter((item) =>
    config.dikios.categories.interaction.principles.includes(
      parseInt(item.principleId)
    )
  );
  const motivation = principlesByOption.filter((item) =>
    config.dikios.categories.motivation.principles.includes(
      parseInt(item.principleId)
    )
  );

  return (
    <Style>
      <div>
        <div>
          <BsDiagram3 />
          {t('CommonTerms.Structure')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={structure}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsGear />
          {t('CommonTerms.Technology')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={technology}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsGlobe2 />
          {t('CommonTerms.Culture')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={culture}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsPeople />
          {t('CommonTerms.Interaction')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={interaction}
          questions={questions}
        />
      </div>

      <div>
        <div>
          <BsLightbulb />
          {t('CommonTerms.Motivation')}
        </div>
        <OurResultByQuestionAndOption
          principlesByOption={motivation}
          questions={questions}
          showLegend={true}
        />
      </div>
    </Style>
  );
};

OurResultByCategory.displayName = 'OurResultByCategory';

const Style = styled.div`
  padding-bottom: 10px;

  > div {
    display: flex;
    align-items: flex-start;

    > div:first-child {
      width: 100px;

      svg {
        margin-right: 10px;
      }
    }

    > div:last-child {
      flex: 1;
    }
  }

  > div:not(:last-child) {
    margin-bottom: -10px;
  }

  @media screen and (max-width: ${Breakpoints.small}) {
    padding-bottom: 30px;

    > div {
      display: block;

      > div:first-child {
        width: auto;
      }
    }
  }
`;
