import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getHistorical = createAsyncThunk(
  'report/historical',
  async ({
    entityId,
    reportId,
    fileName,
  }: {
    entityId: string;
    reportId: string;
    fileName: string;
  }) => {
    return axios({
      url: `${config.apiUrl.product.dikios}entities/${entityId}/reports/${reportId}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const file = new File([response.data], fileName);

      saveAs(file, fileName);
    });
  }
);

export const {
  reducer: reportHistoricalReducer,
  actions: reportHistoricalActions,
} = createSlice({
  name: 'report/historical',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getHistorical.fulfilled, (state) => {
        state.isPosting = false;
      })
      .addCase(getHistorical.pending, (state) => {
        state.isPosting = true;
        state.error = null;
      })
      .addCase(getHistorical.rejected, (state, action) => {
        state.isPosting = false;
        state.error = action.error.name;
      });
  },
});

export const selectReportHistoricalError = (
  state: RootState
): string | null | undefined => state.report.historical.error;

export const selectReportHistoricalIsPosting = (state: RootState): boolean =>
  state.report.historical.isPosting;
