import { Chart } from '@dimatech/features-core/lib/components/Chart';
import { RadarChartAxisTick } from '@dimatech/features-core/lib/components/RadarChartAxisTick';
import { Theme } from '@dimatech/shared/lib/themes';
import { ChartTooltipSplit } from 'components/ChartTooltipSplit';
import { PrinciplesByCategory } from 'models';
import { useTranslation } from 'react-i18next';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar as RadarChartRadar,
  Tooltip,
} from 'recharts';
import { withTheme } from 'styled-components';
import { formatAsPercent } from 'utils';

export const ResultByCategory = withTheme(
  ({
    theme,
    principlesByCategory,
  }: {
    theme: Theme;
    principlesByCategory?: PrinciplesByCategory;
  }): JSX.Element | null => {
    const { t } = useTranslation();

    const data = principlesByCategory
      ? [
          {
            key: t(`CommonTerms.${principlesByCategory.structure.key}`),
            percentage: principlesByCategory.structure.percentage,
          },
          {
            key: t(`CommonTerms.${principlesByCategory.technology.key}`),
            percentage: principlesByCategory.technology.percentage,
          },
          {
            key: t(`CommonTerms.${principlesByCategory.culture.key}`),
            percentage: principlesByCategory.culture.percentage,
          },
          {
            key: t(`CommonTerms.${principlesByCategory.interaction.key}`),
            percentage: principlesByCategory.interaction.percentage,
          },
          {
            key: t(`CommonTerms.${principlesByCategory.motivation.key}`),
            percentage: principlesByCategory.motivation.percentage,
          },
        ]
      : [];

    if (principlesByCategory && !principlesByCategory.technology.percentage) {
      return (
        <Chart>
          <div style={{ fontSize: theme.fonts.size.base }}>
            {t('AllResult.NoData')}
          </div>
        </Chart>
      );
    }

    return data && data.length > 0 ? (
      <Chart>
        <RadarChart
          style={{ height: '105%' }}
          cx="50%"
          cy="50%"
          outerRadius="85%"
          data={data}
        >
          <PolarGrid stroke={theme.colors.chart.gridStroke} strokeWidth={0.5} />
          <PolarAngleAxis
            dataKey="key"
            strokeWidth={0.5}
            stroke={theme.colors.chart.axisStroke}
            tickLine={false}
            tick={({ x, y, payload }) => (
              <RadarChartAxisTick x={x} y={y} payload={payload} />
            )}
          />

          <PolarRadiusAxis
            angle={90}
            domain={[0, 100]}
            tickCount={5}
            strokeWidth={0.5}
            tickLine={false}
            tickFormatter={(value) => formatAsPercent(value, '0')}
            stroke={theme.colors.chart.axisStroke}
          />

          <RadarChartRadar
            dataKey="percentage"
            name={t(`CommonTerms.Percentage`)}
            stroke={theme.colors.chart.primary}
            fill={theme.colors.chart.primary}
            fillOpacity={0.3}
          />

          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<ChartTooltipSplit />}
          />
        </RadarChart>
      </Chart>
    ) : (
      <Chart />
    );
  }
);

ResultByCategory.displayName = 'ResultByCategory';
