import { Gauge } from '@dimatech/features-core/lib/components/Gauge';
import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { Principle } from 'models';
import { Trans, useTranslation } from 'react-i18next';
import { BsArrowRepeat } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';

export const Statistics = withTheme(
  ({
    theme,
    principles,
  }: {
    theme: Theme;
    principles: Principle[];
  }): JSX.Element => {
    const { t } = useTranslation();
    const digitalCompetence =
      (principles.reduce((a, b: Principle) => a + (b.percentage ?? 0), 0) /
        principles.length) *
      100;

    const percentageDontKnow =
      (principles.reduce(
        (a, b: Principle) => a + (b.percentageDontKnow ?? 0),
        0
      ) /
        principles.length) *
      100;
    return (
      <Style>
        <Gauge
          value={digitalCompetence}
          defaultValue="-"
          name={t('CommonTerms.DigitalCompetence')}
          text={<Trans i18nKey="Overview.Statistics.DigitalCompetence.Help" />}
        />

        <Number
          icon={<BsArrowRepeat />}
          color={theme.colors.secondary}
          text={t('CommonTerms.PercentDontKnow')}
          formattedValue={formatAsPercent(percentageDontKnow, '0')}
        />
      </Style>
    );
  }
);

Statistics.displayName = 'Statistics';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  ul,
  ol {
    margin-left: 20px;
  }

  > div {
    margin-right: 15px;
  }
`;
