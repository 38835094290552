import { TooltipStyle } from '@dimatech/shared/lib/components/tooltip';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import i18n from 'localization';
import { PrincipleOptionByPrinciple, Question } from 'models';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled, { withTheme } from 'styled-components';
import { formatAsNumber, formatAsPercent, toTitleCase } from 'utils';

export const OurResultByQuestionAndOption = withTheme(
  ({
    theme,
    principlesByOption,
    questions,
    showLegend,
  }: {
    theme: Theme;
    principlesByOption: PrincipleOptionByPrinciple[];
    questions: Question[];
    showLegend?: boolean;
  }): JSX.Element => {
    const { t } = useTranslation();

    const mapped = principlesByOption.map((principle) => {
      const question = questions.find(
        (question) => question.id.toString() === principle.principleId
      );

      return {
        sortOrder: question?.sortOrder ?? 0,
        label: t(
          `Survey.${config.survey?.id}.Question.${principle.principleId}.Principle`
        ),
        question: t(
          `Survey.${config.survey?.id}.Question.${principle.principleId}.Label`
        ),
        option1:
          principle.options.find(
            (option) =>
              option.optionId === config.dikios.principalOptions[0].toString()
          )?.count ?? 0,
        option2:
          principle.options.find(
            (option) =>
              option.optionId === config.dikios.principalOptions[1].toString()
          )?.count ?? 0,
        option3:
          principle.options.find(
            (option) =>
              option.optionId === config.dikios.principalOptions[2].toString()
          )?.count ?? 0,
        option4:
          principle.options.find(
            (option) =>
              option.optionId === config.dikios.principalOptions[3].toString()
          )?.count ?? 0,
        option5:
          principle.options.find(
            (option) =>
              option.optionId === config.dikios.principalOptions[4].toString()
          )?.count ?? 0,
        option6:
          principle.options.find(
            (option) =>
              option.optionId === config.dikios.principalOptions[5].toString()
          )?.count ?? 0,
        option7:
          principle.options.find(
            (option) =>
              option.optionId === config.dikios.principalOptions[6].toString()
          )?.count ?? 0,
      };
    });

    const sorted = mapped.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));

    const formatter = (value: string) => formatAsNumber(value) as string;

    const chartLineHeight = 22;
    const chartHeight =
      chartLineHeight * principlesByOption.length + 29 + (showLegend ? 10 : 0);

    return (
      <Style
        style={{
          height: `${chartHeight}px`,
        }}
      >
        <ResponsiveContainer width="100%" height="100%" debounce={1}>
          <BarChart
            layout="vertical"
            data={sorted}
            margin={{
              left: 20,
              right: 20,
            }}
          >
            <CartesianGrid
              stroke={theme.colors.chart.gridStroke}
              strokeWidth={0.5}
            />

            <XAxis
              tickLine={false}
              stroke={theme.colors.chart.axisStroke}
              type="number"
              domain={[0, 'dataMax']}
              tickCount={10}
              interval="preserveStartEnd"
              tickFormatter={formatter}
              tick={false}
            />

            <YAxis
              tickLine={false}
              stroke={theme.colors.onSurface}
              axisLine={{ stroke: theme.colors.chart.axisStroke }}
              dataKey="label"
              type="category"
              width={110}
              interval={0}
            />

            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<TooltipByOption />}
            />

            <Bar
              dataKey="option1"
              stackId="a"
              fill={theme.colors.chart.scale[0]}
            />
            <Bar
              dataKey="option2"
              stackId="a"
              fill={theme.colors.chart.scale[1]}
            />
            <Bar
              dataKey="option3"
              stackId="a"
              fill={theme.colors.chart.scale[2]}
            />
            <Bar
              dataKey="option4"
              stackId="a"
              fill={theme.colors.chart.scale[3]}
            />
            <Bar
              dataKey="option5"
              stackId="a"
              fill={theme.colors.chart.scale[4]}
            />
            <Bar
              dataKey="option6"
              stackId="a"
              fill={theme.colors.chart.scale[5]}
            />
            <Bar dataKey="option7" stackId="a" fill={theme.colors.border} />

            {showLegend && (
              <Legend
                verticalAlign="bottom"
                height={10}
                iconType="circle"
                formatter={(value) =>
                  t(
                    `QuestionAnalyze.OurResultByQuestionAndOption.Options.${toTitleCase(
                      value,
                      true
                    )}`
                  )
                }
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </Style>
    );
  }
);

OurResultByQuestionAndOption.displayName = 'OurResultByQuestionAndOption';

const TooltipByOption = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: {
    name: string;
    label: string;
    question: string;
    value: string;
    payload: { key: string; label: string; question: string };
  }[];
}): JSX.Element | null => {
  const { t } = useTranslation();

  const formatter = (value: string) => (+value).toLocaleString(i18n.language);
  const formatterPercent = (value: string, sum: number) => {
    const numeric = parseFloat(value);

    return formatAsPercent(
      isNaN(numeric) || numeric === 0 ? undefined : (numeric / sum) * 100,
      '0'
    ) as string;
  };

  if (active && payload && payload.length) {
    const sum = payload.reduce((value, item) => {
      return value + parseFloat(item.value);
    }, 0);

    return (
      <TooltipStyle>
        <div className="tooltip-title">
          {payload[0].payload.key ?? payload[0].payload.label}
        </div>

        <div style={{ marginBottom: 10 }}>
          {payload[0].payload.key ?? payload[0].payload.question}
        </div>

        {payload.map((item, index) => (
          <div key={index}>
            {`${t(
              `QuestionAnalyze.OurResultByQuestionAndOption.Options.${toTitleCase(
                item.name,
                true
              )}`
            )}: ${formatter(item.value)} (${formatterPercent(
              item.value,
              sum
            )})`}
          </div>
        ))}
      </TooltipStyle>
    );
  }

  return null;
};

const Style = styled.div`
  width: 100%;

  .recharts-tooltip-wrapper {
    z-index: 2;
  }

  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xxs};

  @media screen and (max-width: ${Breakpoints.small}) {
    max-width: 500px !important;
  }
`;
