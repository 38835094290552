import { appReducer } from '@dimatech/features-core/lib/api/appSlice';
import { featuresCoreApi } from '@dimatech/features-core/lib/api/featuresCoreApi';
import { messageTemplateReducer } from '@dimatech/features-core/lib/api/message-template/messageTemplateSlice';
import { messageReducer } from '@dimatech/features-core/lib/api/message/messageSlice';
import { userExportReducer } from '@dimatech/features-core/lib/api/user/userExportSlice';
import { userReducer } from '@dimatech/features-core/lib/api/user/userSlice';
import { entityReducer } from '@dimatech/features-survey/lib/api/entity/entitySlice';
import { entitySurveyAnonRespondentReducer } from '@dimatech/features-survey/lib/api/entity/entitySurveyAnonRespondentSlice';
import { entitySurveyRespondentReducer } from '@dimatech/features-survey/lib/api/entity/entitySurveyRespondentSlice';
import { featuresSurveyApi } from '@dimatech/features-survey/lib/api/featuresSurveyApi';
import { filterReducer } from '@dimatech/features-survey/lib/api/filter/filterSlice';
import { survey } from '@dimatech/features-survey/lib/api/survey/surveySlice';
import { combineReducers } from '@reduxjs/toolkit';
import { dikiosApi } from 'api/dikiosApi';
import { dikiosReducer } from 'api/dikiosSlice';
import { report } from 'api/report/reportSlice';

export const rootReducer = combineReducers({
  app: appReducer,
  dikios: dikiosReducer,
  entity: entityReducer,
  report,
  survey,
  messageTemplate: messageTemplateReducer,
  userExport: userExportReducer,
  message: messageReducer,
  user: userReducer,
  entitySurveyRespondent: entitySurveyRespondentReducer,
  entitySurveyAnonRespondent: entitySurveyAnonRespondentReducer,
  filter: filterReducer,
  [dikiosApi.reducerPath]: dikiosApi.reducer,
  [featuresCoreApi.reducerPath]: featuresCoreApi.reducer,
  [featuresSurveyApi.reducerPath]: featuresSurveyApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
