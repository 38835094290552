import { groupBy } from '@dimatech/shared/lib/utils';
import { config } from 'config';
import {
  Principle,
  PrincipleOption,
  PrincipleOptionByPrinciple,
  PrinciplesByCategory,
  PrinciplesByEntity,
  StatementCategory,
  SubCategory,
  SubCategoryDefinition,
} from 'models';

// Used for calculations
type Category = SubCategory & {
  key: string;
  categories?: Category[];
  totalPercentage: number;
  totalAverage: number;
  totalPrinciples: number;
  totalPrinciplesHasValue: number;
  totalPercentageDontKnow: number;
};

/**
 * Map principles to PrincipleOptionByPrinciple and calculates averages
 * @param principles
 * @returns
 */
export const mapToPrincipleOptionByPrinciple = (
  principles: PrincipleOption[]
): PrincipleOptionByPrinciple[] => {
  const groupedResult = groupBy(
    principles,
    (principle) => principle.principleId
  );

  const principleOptionByPrinciple = Object.keys(groupedResult).map((key) => {
    return {
      principleId: key,
      options: groupedResult[key].items,
    } as PrincipleOptionByPrinciple;
  });

  return principleOptionByPrinciple;
};

/**
 * Map principles to PrinciplesByEntity and calculates averages
 * @param principles
 * @returns
 */
export const mapToPrinciplesByEntity = (
  principles: Principle[]
): PrinciplesByEntity[] => {
  const groupedResult = groupBy(
    principles,
    (principle) => principle.entityId as string,
    (principle) => ({ entityName: principle.entityName })
  );

  const principlesByEntity = Object.keys(groupedResult).map((key) => {
    const categories = mapToPrinciplesByCategory(groupedResult[key].items);

    return {
      entityId: key,
      entityName: groupedResult[key].payload?.entityName,
      percentage:
        groupedResult[key].items.reduce(
          (a, b: Principle) => a + (b.percentage ?? 0),
          0
        ) / groupedResult[key].items.length,
      percentageDontKnow:
        groupedResult[key].items.reduce(
          (a, b: Principle) => a + (b.percentageDontKnow ?? 0),
          0
        ) / groupedResult[key].items.length,
      ...categories,
    } as PrinciplesByEntity;
  });

  return principlesByEntity;
};

/**
 * Map principles to PrinciplesByCategory and calculates averages
 * @param principles
 * @returns
 */
export const mapToPrinciplesByCategory = (
  principles: Principle[]
): PrinciplesByCategory => {
  const total = principles.reduce(
    (total, principle) => {
      // Sum percentage for all categories
      Object.values(StatementCategory).forEach((enumValue) => {
        const key =
          enumValue.toLowerCase() as keyof typeof config.dikios.categories;

        // Check if principle is included in category
        if (
          (
            config.dikios.categories[key] as SubCategoryDefinition
          ).principles.includes(parseFloat(principle.principleId))
        ) {
          // It is, summarize values
          summarizeCategory(enumValue, principle, total);
        }
        //summarizeCategory(enumValue, principle, total);
      });

      return total;
    },

    {} as Category
  );

  // Calc new percentages
  total.categories?.forEach((category) => calculateCategory(category));

  // Map to PrinciplesByCategory
  const principlesByCategory = mapCategoriesToPrinciplesByCategory(total);

  return principlesByCategory;
};

/**
 * Summarize category and subcategory
 * @param total
 */
const summarizeCategory = (
  enumValue: string,
  principle: Principle,
  total: Category
) => {
  if (!total.categories) {
    total.categories = [];
  }

  // Check if sub category exists, else create it
  let category = total.categories.find((s) => s.key === enumValue);

  if (!category) {
    category = {
      key: enumValue,
      percentage: 0,
      totalPercentage: 0,
      totalAverage: 0,
      totalPrinciples: 0,
      totalPercentageDontKnow: 0,
      totalPrinciplesHasValue: 0,
    };

    total.categories.push(category);
  }

  // Add values
  if (principle.percentage) {
    if (principle.average) {
      category.totalAverage += principle.average;
    }

    category.totalPrinciplesHasValue++;
    category.totalPercentage += principle.percentage;
  }

  category.totalPrinciples++;
  category.totalPercentageDontKnow += principle.percentageDontKnow;
};

/**
 * Calculates averages
 * @param category
 */
const calculateCategory = (category: Category): void => {
  if (category.totalPrinciplesHasValue > 0) {
    category.average = category.totalAverage / category.totalPrinciplesHasValue;
    category.percentage =
      (category.totalPercentage / category.totalPrinciplesHasValue) * 100;
  }

  category.percentageDontKnow =
    (category.totalPercentageDontKnow / category.totalPrinciples) * 100;
};

/**
 * Maps categories to PrinciplesByCategory
 * @param ability
 * @param heritage
 * @returns
 */
const mapCategoriesToPrinciplesByCategory = (
  total: Category
): PrinciplesByCategory => {
  const toSubCategory = (category: Category | undefined): SubCategory => ({
    key: category?.key,
    percentage: category?.percentage,
    average: category?.average,
    percentageDontKnow: category?.percentageDontKnow,
  });

  const culture = toSubCategory(
    total.categories?.find(
      (subCategory) => subCategory.key === StatementCategory.Culture
    )
  );

  const interaction = toSubCategory(
    total.categories?.find(
      (subCategory) => subCategory.key === StatementCategory.Interaction
    )
  );

  const motivation = toSubCategory(
    total.categories?.find(
      (subCategory) => subCategory.key === StatementCategory.Motivation
    )
  );

  const structure = toSubCategory(
    total.categories?.find(
      (subCategory) => subCategory.key === StatementCategory.Structure
    )
  );

  const technology = toSubCategory(
    total.categories?.find(
      (subCategory) => subCategory.key === StatementCategory.Technology
    )
  );

  const principlesByCategory: PrinciplesByCategory = {
    culture,
    interaction,
    motivation,
    structure,
    technology,
  };

  return principlesByCategory;
};

export const getSubCategoryDefinitions = (): SubCategoryDefinition[] => [
  { ...config.dikios.categories.culture },
  { ...config.dikios.categories.interaction },
  { ...config.dikios.categories.motivation },
  { ...config.dikios.categories.structure },
  { ...config.dikios.categories.technology },
];
