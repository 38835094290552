import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { PrinciplesByCategory } from 'models';
import { useTranslation } from 'react-i18next';
import {
  BsArrowUpRight,
  BsChatQuote,
  BsDiagram3,
  BsGear,
  BsPatchCheck,
} from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { formatAsPercent } from 'utils';

export const StatisticsDontKnow = withTheme(
  ({
    theme,
    principlesByCategory,
  }: {
    theme: Theme;
    principlesByCategory: PrinciplesByCategory;
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <Style>
        <Number
          icon={<BsDiagram3 />}
          color={theme.colors.secondary}
          text={t('CommonTerms.Structure')}
          formattedValue={formatAsPercent(
            principlesByCategory.structure.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsGear />}
          color={theme.colors.secondary}
          text={t('CommonTerms.Technology')}
          formattedValue={formatAsPercent(
            principlesByCategory.technology.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsPatchCheck />}
          color={theme.colors.secondary}
          text={t('CommonTerms.Culture')}
          formattedValue={formatAsPercent(
            principlesByCategory.culture.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsChatQuote />}
          color={theme.colors.secondary}
          text={t('CommonTerms.Interaction')}
          formattedValue={formatAsPercent(
            principlesByCategory.interaction.percentageDontKnow,
            '0'
          )}
        />

        <Number
          icon={<BsArrowUpRight />}
          color={theme.colors.secondary}
          text={t('CommonTerms.Motivation')}
          formattedValue={formatAsPercent(
            principlesByCategory.motivation.percentageDontKnow,
            '0'
          )}
        />
      </Style>
    );
  }
);

StatisticsDontKnow.displayName = 'StatisticsDontKnow';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};

  > div {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
`;
