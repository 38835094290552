import { ApplicationContext } from '@dimatech/shared/lib/application';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { config } from 'config';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { View } from 'views';

export const Help = (): JSX.Element => {
  const { t } = useTranslation();
  const application = useContext(ApplicationContext);

  return (
    <View title={t('Help.Title')}>
      <ViewHeader>
        <Heading1>{t('Help.Title')}</Heading1>

        {config.version && (
          <Heading1>
            {' '}
            - {application.title} v{config.version}
          </Heading1>
        )}
      </ViewHeader>

      <Card width="100%" title={t('Help.Support')}>
        <CardBody>
          <Trans
            i18nKey="Help.Text"
            values={{
              supportUrl: config.support.url,
              supportEmail: config.support.email,
            }}
          >
            <a href={config.support.url} target="_blank" rel="noreferrer">
              {config.support.url}
            </a>
            <a href={`mailto: ${config.support.email}`}>
              {config.support.email}
            </a>
          </Trans>
        </CardBody>
      </Card>

      <Card width="100%" title={t('Help.Messaging')}>
        <CardBody>
          <BsExclamationCircleFill
            className="size-xl"
            style={{ marginRight: 8 }}
          />
          {t('Administrate.Schedule.Help.Whitelisting', {
            email: config.support.productEmail,
          })}

          <p>{t('Administrate.Schedule.Help.TestMessage')}</p>
        </CardBody>
      </Card>

      <Card width="100%" title={t('Help.Anonymity.Title')}>
        <CardBody>{t('Help.Anonymity.Text')}</CardBody>
      </Card>

      <Card width="100%" title={t('Help.Calculations')}>
        <CardBody>
          <Trans i18nKey="Overview.Statistics.DigitalCompetence.Help" />
        </CardBody>
      </Card>
    </View>
  );
};

Help.displayName = 'Help';
