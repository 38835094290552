import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CustomerAccountInfo } from '@dimatech/features-core/lib/features/authentication/components';
import { useGetEntitiesWithSurveyQuery } from '@dimatech/features-survey/lib/api/entity/entityApi';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { RefreshData } from '@dimatech/shared/lib/components/RefreshData';
import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeaderActions,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CacheTags, dikiosApi } from 'api/dikiosApi';
import { selectFilterByEntity } from 'api/dikiosSlice';
import { useGetProgressByEntityQuery } from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProgressByEntity } from 'models';
import { useContext, useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntitiesInPath } from 'utils';
import { StatusByEntity } from './components/StatusByEntity';

export const AdministrateViewStatus = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filterByEntity = useAppSelector(selectFilterByEntity);
  const { accessToken } = useContext(AuthenticationContext);
  const customerId = accessToken.customerId;

  const isCarelessRespondentEnabledFlagOn =
    useFlags()[flags.permanent.app.dikios.isCarelessRespondentEnabled];

  const statusByEntityId = useId();

  const [progress, setProgress] = useState<ProgressByEntity[]>();

  const { data: entities, isFetching: isFetchingEntities } =
    useGetEntitiesWithSurveyQuery(
      customerId && accessToken.user?.id && filterByEntity.instance?.id
        ? {
            _customerId: customerId,
            _userId: accessToken.user?.id,
            surveyInstanceId: filterByEntity.instance.id,
          }
        : skipToken
    );

  const {
    data: progressByEntity,
    isFetching,
    isLoading,
  } = useGetProgressByEntityQuery(
    filterByEntity.instance && customerId && accessToken.user?.id
      ? {
          filter: {
            ...filterByEntity,
            includeOnlyInvolvedRespondents: false,
            includePoos: true,
          },
          _customerId: customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const handleRefresh = () => {
    dispatch(dikiosApi.util.invalidateTags([CacheTags.ProgressByEntity]));
  };

  useEffect(() => {
    if (!progressByEntity || !entities) {
      return;
    }

    // Copy path info from entities to progress items
    const entitiesInPath = getEntitiesInPath(entities, filterByEntity.entityId);

    const all = progressByEntity.map((item) => {
      const entity = entitiesInPath.find(
        (entity) => item.entityId === entity.id
      );

      return {
        ...item,
        entityName: entity?.name,
        entityId: entity?.id,
        entityPath: entity?.path,
      } as ProgressByEntity;
    });

    setProgress(all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressByEntity, entities, filterByEntity.entityId]);

  if (isLoading) {
    return <LoaderFill />;
  }

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.ViewStatus.Title')}</Heading1>
        <SelectExtendedFilters
          isPooFilterShown={false}
          isRespondentFilterShown={false}
          isOnlyPeriodsWithDataShown={true}
          isCarelessFilterShown={isCarelessRespondentEnabledFlagOn}
          isOccupationFilterShown={false}
        />
        <ViewHeaderActions>
          <RefreshData handleRefresh={handleRefresh} />
        </ViewHeaderActions>
      </ViewHeader>

      <CustomerAccountInfo />

      {progress && progress.length === 0 && !isFetching && (
        <Card width="100%">
          <AlertInfo>{t('Administrate.ViewStatus.NoData')}</AlertInfo>
        </Card>
      )}

      {progress && progress.length > 0 && (
        <Card
          title={t('Administrate.ViewStatus.StatusByEntity.Title')}
          width="100%"
          canBeSavedAsPng={true}
          canBeSavedAsCsv={true}
          saveToPngElementId={statusByEntityId}
        >
          <CardBody isLoading={isFetching || isFetchingEntities}>
            <StatusByEntity
              progressByEntity={progress}
              saveToPngElementId={statusByEntityId}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

AdministrateViewStatus.displayName = 'AdministrateViewStatus';
