import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { useTranslation } from 'react-i18next';
import { ReportList } from './components/ReportList';

export const GlobalAdministrateReports = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <ViewHeader>
        <Heading1>{t('GlobalAdministrate.Reports.Title')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody>
          <ReportList />
        </CardBody>
      </Card>
    </>
  );
};

GlobalAdministrateReports.displayName = 'GlobalAdministrateReports';
