import { HelpIcon } from '@dimatech/shared/lib/components/HelpIcon';
import { Progress } from '@dimatech/shared/lib/components/Progress';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  TdRight,
  Th,
  ThCentered,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { PrinciplesByEntity, SortDirection } from 'models';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatAsPercent } from 'utils';

export const ChildResultPerCategory = ({
  principlesByEntity,
  saveToPngElementId,
}: {
  principlesByEntity: PrinciplesByEntity[];
  saveToPngElementId?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const formatter = (value: string | number | undefined) =>
    formatAsPercent(value, '-') as string;

  const { items, sorter } = useSortableData(principlesByEntity, {
    key: 'name',
    direction: SortDirection.Asc,
  });

  return (
    <TableResponsiveContainer>
      <Table id={saveToPngElementId}>
        <thead>
          <tr>
            <Th sortKey="entityName" sorter={sorter}>
              {t('CommonTerms.Entity')}
            </Th>

            <ThRight
              sortKey="structure"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.structure.percentage,
                  b: b.structure.percentage,
                };
              }}
            >
              {t('CommonTerms.Structure')}
            </ThRight>

            <ThRight
              sortKey="Technology"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.technology.percentage,
                  b: b.technology.percentage,
                };
              }}
            >
              {t('CommonTerms.Technology')}
            </ThRight>

            <ThRight
              sortKey="Culture"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.culture.percentage,
                  b: b.culture.percentage,
                };
              }}
            >
              {t('CommonTerms.Culture')}
            </ThRight>

            <ThRight
              sortKey="interaction"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.interaction.percentage,
                  b: b.interaction.percentage,
                };
              }}
            >
              {t('CommonTerms.Interaction')}
            </ThRight>

            <ThRight
              sortKey="motivation"
              sorter={sorter}
              sortPredicate={(a, b) => {
                return {
                  a: a.motivation.percentage,
                  b: b.motivation.percentage,
                };
              }}
            >
              {t('CommonTerms.Motivation')}
            </ThRight>

            <ThCentered
              style={{ width: 150 }}
              sortKey="digitalCompetence"
              sorter={sorter}
              sortPredicate={({ percentage: a }, { percentage: b }) => {
                return { a, b };
              }}
            >
              {t('CommonTerms.DigitalCompetence')}

              <HelpIcon
                showTiny={true}
                title={t('CommonTerms.DigitalCompetence')}
                text={
                  <Trans i18nKey="Overview.Statistics.DigitalCompetence.Help" />
                }
              />
            </ThCentered>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => (
            <Fragment key={index}>
              <Tr>
                <Td>{item.entityName}</Td>
                <TdRight data-value={item.structure.percentage}>
                  {formatter(item.structure.percentage)}
                </TdRight>
                <TdRight data-value={item.technology.percentage}>
                  {formatter(item.technology.percentage)}
                </TdRight>
                <TdRight data-value={item.culture.percentage}>
                  {formatter(item.culture.percentage)}
                </TdRight>
                <TdRight data-value={item.interaction.percentage}>
                  {formatter(item.interaction.percentage)}
                </TdRight>
                <TdRight data-value={item.motivation.percentage}>
                  {formatter(item.motivation.percentage)}
                </TdRight>
                <TdCentered data-value={item.percentage * 100}>
                  <Progress
                    formatter={formatter}
                    singleValue={true}
                    value={item.percentage * 100}
                  />
                </TdCentered>
              </Tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
    </TableResponsiveContainer>
  );
};

ChildResultPerCategory.displayName = 'ChildResultPerCategory';
