import { AdministrateSchedule as AdministrateScheduleFeature } from '@dimatech/features-survey/lib/features/administrate-schedule';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateSchedule = (): JSX.Element | null => {
  const { t } = useTranslation();

  const isAllowEntitySelectionForAnonymousUsersEnabledFlagOn =
    useFlags()[
      flags.permanent.app.dikios.isAllowEntitySelectionForAnonymousUsersEnabled
    ];

  return (
    <View title={t('Administrate.Schedule.Title')}>
      <AdministrateScheduleFeature
        enableAllowEntitySelectionForAnonymousUsers={
          isAllowEntitySelectionForAnonymousUsersEnabledFlagOn
        }
      />
    </View>
  );
};

AdministrateSchedule.displayName = 'AdministrateSchedule';
