export enum StatementCategory {
  Structure = 'Structure',
  Technology = 'Technology',
  Culture = 'Culture',
  Interaction = 'Interaction',
  Motivation = 'Motivation',
}

export type PrinciplesByCategory = {
  structure: SubCategory;
  technology: SubCategory;
  culture: SubCategory;
  interaction: SubCategory;
  motivation: SubCategory;
};

export type PrinciplesByEntity = PrinciplesByCategory & {
  entityId: string;
  entityName: string;
  percentage: number;
  startDate: string;
  endDate: string;
  percentageDontKnow: number;
};

export type SurveyOverview = {
  principles: Principle[];
  categories: PrinciplesByCategory;
};

export type Principle = {
  principleId: string;
  average: number | null;
  percentageDontKnow: number;
  percentage: number | null;
  name?: string;
  entityName?: string;
  entityId?: string;
};

export type PrincipleOption = {
  principleId: string;
  optionId: string;
  count: number;
};

export type PrincipleOptionByPrinciple = {
  principleId: string;
  options: PrincipleOption[];
};

export type CategoryCustomer = {
  percentage: number;
  average: number;
  customerId: string;
  organization: string;
};

export type Overview = {
  principles: Principle[];
};

export type SubCategory = {
  key?: string;
  percentageDontKnow?: number;
  percentage?: number;
  average?: number;
};

export type SubCategoryDefinition = {
  key: StatementCategory;
  id: number;
  principles: number[];
};
